import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import { useMainIngestMutation, useMainPatchMutation, useMainRetrieveQuery } from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import Select from "components/Select"
import NumberField from "components/shared/NumberField"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import _ from "lodash"
import LatLngPicker from "components/back/LatLngPicker"

function Neighborhoods() {
	const q = useMainRetrieveQuery({ action: "getNeighborhoods", tags: ["neighborhoods"] })
	const communities = useMainRetrieveQuery({ action: "getCommunities", tags: ["communities"] })
	const [create, createData] = useMainIngestMutation()
	const [patch, patchData] = useMainPatchMutation()
	const [neighborhoods, setNeighborhoods] = useState([])
	const [editData, setEditData] = useState(null)
	const [editModal, setEditModal] = useState(false)
	const [markerWipe, triggerMarkerWipe] = useState(false)
	let model = {
		name: "",
		community: "",
	}
	useEffect(() => {
		if (q.data) {
			setNeighborhoods(buildRows(q.data))
		}
	}, [q])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return Yup.object().shape({
			name: Yup.string().required("Required"),
		})
	}

	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Name",
		},
		{
			id: "community",
			header: "MPC",
			accessorFn: r => helpers.translateID('community', r, communities)
		},
		{
			id: "city",
			header: "City",
		},
		{
			id: "state",
			header: "State",
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					if (props?.new) {
						resp = await create({ ...values, action: "createNeighborhood", tags: ["neighborhoods"] })
					} else {
						resp = await patch({
							...values,
							type: "neighborhoods",
							action: "updateResource",
							tags: ["neighborhoods"],
						})
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f.values) */}
							<ul className="formList">
								<li className={helpers.formField(f, "name")}>
									<ErrorMessage name="name" component={FormError} />
									<label>Name</label>
									<Field type="text" name="name" />
								</li>
								<li className={helpers.formField(f, "community")}>
									<ErrorMessage name="community" component={FormError} className="text-red-500" />
									<label>MPC</label>
									<Select
										name="community"
										value={f.values.community}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(communities)}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "lat")}>
									<ErrorMessage name="lat" component={FormError} />
									<label>Latitude</label>
									<NumberField
										name="lat"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.lat}
										error={f.errors.lat}
										value={f.values.lat}
										thousandSeparator={true}
										decimals={8}
										float={true}
									/>
								</li>
								<li className={helpers.formField(f, "lng")}>
									<ErrorMessage name="lng" component={FormError} />
									<label>Longitude</label>
									<NumberField
										name="lng"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.lng}
										error={f.errors.lng}
										value={f.values.lng}
										thousandSeparator={true}
										decimals={8}
										float={true}
									/>
								</li>
							</ul>
							<h3 className="mt-24">Select Center Point</h3>
							<LatLngPicker height={400} formik={f} {...props} triggerWipe={markerWipe} />
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	return (
		<div className="">
			<div className="group">
				<h2>Add Neighborhoods</h2>
				<DisplayForm new={true} />
			</div>
			<h2 className="mt-12">Current Neighborhoods</h2>
			<FullTable data={neighborhoods} columns={columns} admin={true} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit Neighborhoods</h2>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Neighborhoods
